<template>
  <div class="row" style="background:#ededee; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2); padding-bottom: 25px;">
    <div class="col-md-12 col-sm-12 mt-1">
      <div class="row mt-1">
        <!-- <div class="col-md-12"> -->
            <!-- <span class="text-left" :class="$style.title">PLATFORMS</span> -->
            <!-- <span :class="$style.notificationNumber" v-if="lmsHomeworkCount !== 0">{{lmsHomeworkCount}}</span> -->
        <!-- </div> -->
      </div>
      <div  class="row mt-2">
        <br/>
        <div v-if=" roleType =='student' && (Number(this.grade) > 1 && Number(this.grade) < 10 && Number(this.grade) != 8)" class="col-md-6 mt-1">
          <a :href="`https://www.myeduassessment.com/#/login${querystingtext}`" target="blank">
              <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnAssessment]"></div>
              <div :class="$style.txtPlatform">myEDUASSESSMENT</div>
            </div>
          </a>
        </div>
        <div v-if=" roleType =='teacher' && (this.findTeacGrade(2, 7) || this.findTeacGrade(9, 10))" class="col-md-6 mt-1">
          <a :href="`https://report.myeduassessment.com/#/login${querystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnAssessment]"></div>
              <div :class="$style.txtPlatform">myEDUASSESSMENT</div>
            </div>
          </a>
        </div>

        <div v-if="(Number(this.grade) >= 1 && Number(this.grade) < 5) || (this.findTeacGrade(1, 4))" class="col-md-6 mt-2">
          <!-- 1-2-3-4 Sınıflar -->
          <a :href="`https://www.kidzwonder.com${querystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnKidzwonder]"></div>
              <div :class="$style.txtPlatform">KIDZWONDER</div>
            </div>
          </a>
        </div>

        <div v-if="(Number(this.grade) >= 5 && Number(this.grade) < 8) || (this.findTeacGrade(5, 7))" class="col-md-6 mt-2">
          <!-- 1-2-3-4 Sınıflar -->
          <a :href="`https://www.hexagonia.com.tr${querystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnHexagonia]"></div>
              <div :class="$style.txtPlatform">HEXAGONIA</div>
            </div>
          </a>
        </div>

        <!-- <div class="col-md-6 mt-2">
          <a href="https://sterlingenglish.eltdigitaleducation.com/?lang=en" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnSterling]"></div>
              <div :class="$style.txtPlatform">Sterling English E-learning</div>
            </div>
          </a>
        </div> -->


        <div v-if="(Number(this.grade) >= 6 && Number(this.grade) < 10 && Number(this.grade) != 8) || (this.findTeacGrade(6, 7) || this.findTeacGrade(9, 10))" class="col-md-6 mt-2" style="text-align:center;">
          <!-- 1-2-3-4 Sınıflar -->
          <a :href="`https://www.helbling-ezone.com`" target="_blank">
              <div :class="$style.divPlatform">
              <div :class="$style.divPlatformBtnModal" style="padding-top:50px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="50" viewBox="0 0 112.334 24">
                <g id="e-zone_Logo" data-name="e-zone Logo" transform="translate(-162.233 -96.251)">
                  <path id="Pfad_24967" data-name="Pfad 24967" d="M258.028,106.129c.256-3.372,2.52-6.234,6.32-6.234a6.354,6.354,0,0,1,6.363,6.234Zm12.6,6.15c-.64,2.86-2.733,4.313-5.722,4.313-4.826,0-7-3.417-6.876-7.259h16.526c.213-5.338-2.177-12.641-9.992-12.641-6.021,0-10.376,4.869-10.376,11.487.213,6.747,3.543,11.616,10.589,11.616,4.955,0,8.456-2.648,9.438-7.515Zm-36.905,7.385h3.811V102.849h.1l10.409,16.815h4.229V96.8h-3.812v16.846h-.063L237.948,96.8h-4.227ZM220.879,99.518c4.9,0,6.95,4.325,6.95,8.713s-2.05,8.711-6.95,8.711-6.951-4.323-6.951-8.711,2.05-8.713,6.951-8.713m0-3.267c-6.854,0-10.953,5.446-10.953,11.979s4.1,11.978,10.953,11.978,10.953-5.444,10.953-11.978-4.1-11.979-10.953-11.979m-28.664,4h12.3L191.19,116.4v3.267h18.736V116.2H196.281L209.6,99.839V96.8h-17.39Zm-24.025,6.239a4.692,4.692,0,0,1,4.948-4.614c2.895,0,4.194,1.594,4.74,4.614Zm15.643,3.774c.42-6.669-3.146-12.876-10.526-12.876-6.585,0-11.073,4.948-11.073,11.449,0,6.711,4.237,11.409,11.073,11.409,4.907,0,8.471-2.18,10.149-7.3h-5.242c-.379,1.341-2.308,2.809-4.7,2.809-3.313,0-5.158-1.719-5.325-5.493Z" fill="#3a3c3c"/>
                  <path id="Pfad_24968" data-name="Pfad 24968" d="M222.2,123.536a3.512,3.512,0,1,0-3.513-3.513,3.514,3.514,0,0,0,3.513,3.513" transform="translate(-32.862 -11.795)" fill="#ea5901"/>
                  <rect id="Rechteck_12508" data-name="Rechteck 12508" width="6.484" height="6.484" transform="translate(217.821 104.987)" fill="#ea5901"/>
                </g>
              </svg>

              </div>
              <div :class="$style.txtPlatform">E-Zone</div>
            </div>
          </a>
        </div>


        <template v-if="roleType =='student' && this.rlpCode != null && this.rlpCode != '###'">
          <div class="col-md-6 mt-2">
            <a data-toggle="modal" data-target="#rlpDescription">             
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnRLP]"></div>
                <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div>
              </div>
            </a>
          </div>
        </template>
        <template v-if="roleType =='teacher' && this.rlpCode != null">
          <div class="col-md-6 mt-2" >
            <a :href="`https://richmondlp.com/login`" target="blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnRLP]"></div>
                <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div>
              </div>
            </a>
          </div>
        </template>


      </div>

      <div v-if="roleType === 'teacher'" class="row mt-2">

        <div class="col-md-6 mt-1" data-toggle="modal" data-target="#exampleModalCenter2">
          <a>
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnPdf]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>

        <div class="col-md-6 mt-2">
          <a :href="`https://www.uesportals.com/#/login/signin${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
            <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnReports]"></div>
            <div :class="$style.txtPlatform">REPORT</div>
            </div>
          </a>
        </div>
        <!-- <div class="col-md-4 mt-1">
          <a :href="`#`">
              <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnVlp]"></div>
            </div>
          </a>
        </div> -->
      </div>
      
      <div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle2">Select Your Class</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <a :href="`https://drive.google.com/drive/folders/1gFAN8nbIdvDKFKysuUCfRUGSURo3JZ6c?usp=sharing`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnPdf]"></div>
                      <div :class="$style.txtPlatform">ANNOUNCEMENTS & LOCAL REPORTS</div>
                    </div>
                  </a>
                </div>
                <div class="col-md-12 mt-2">
                  <a :href="`https://drive.google.com/drive/folders/1yB0uYF-t9FDgK3qx5PDmYvS928otBseI?usp=sharing`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnPdf]"></div>
                      <div :class="$style.txtPlatform">KINDERGARDEN</div>
                    </div>
                  </a>
                </div>
                <div class="col-md-12 mt-2">
                  <a :href="`https://drive.google.com/drive/folders/1jawfwaAWFu-awh6LWRNOAOWuD79zdWD_?usp=sharing`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnPdf]"></div>
                      <div :class="$style.txtPlatform">PRIMARY</div>
                    </div>
                  </a>
                </div>
                <div class="col-md-12 mt-2">
                  <a :href="`https://drive.google.com/drive/folders/1_0FXn3ecY3pAu_2dmQsppfD6AhlyPvZs?usp=sharing`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnPdf]"></div>
                      <div :class="$style.txtPlatform">MIDDLE</div>
                    </div>
                  </a>
                </div>
                <div class="col-md-12 mt-2">
                  <a :href="`https://drive.google.com/drive/folders/1_IaKY1J1iZLRIjFGZKx-IGz-1Hg1zL7u?usp=sharing`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnPdf]"></div>
                      <div :class="$style.txtPlatform">HIGH</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Select Your Class</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 mt-2" v-for="(item, i) in zoom" :key="i">
                  <a @click="getZoomTokenTeacher(`${item.id}-${item.name}`)">
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnMyEduCams]"></div>
                    <div :class="$style.txtPlatform">{{item.name}}</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="rlpDescription" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle1">Richmond Learning Platform Aktivasyon Adımları</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              
              <div class="col-md-12 mt-1">
                <!-- <a :href="`https://www.kidzwonder.com${this.gndkwdemo1}`" target="_blank"> -->
                  <div :class="$style.divPlatform">
                    <template v-if="this.rlpCode != null && this.rlpCode != '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız, öncelikle aşağıdaki <b>"Kodu Kopyala"</b> butonuna basarak kitap kodunuzu kopyalayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kopyaladığınız kodu <b>"Please Enter a Code"</b> alanına yapıştırınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Kodu yapıştırdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">7.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>
                    <template v-else-if="this.rlpCode == '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kitabınızda ön kapak içinde bulunan kodu <b>"Please Enter a Code"</b> alanına yazınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b>	Kodu yazdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>


                  </div>
                <!-- </a> -->
              </div>

              <div class="col-md-12 mt-1" v-if="this.rlpCode != null && this.rlpCode != '###'">
                  <div :class="$style.divPlatform" style="height:auto !important">
                    <div class="row mt-3 mb-3">
                      <div class="col-8">
                        <p style="margin: 5px 5px 5px 5px" ref="code" v-on:focus="$event.target.select()" >{{rlpCode}}</p>
                        <input type="hidden" id="rlp-code" :value="rlpCode">
                      </div>
                      
                      <div class="col-4">                      
                        <button type="button" @click="copyCode()" class="btn btn-primary">Kodu Kopyala</button>
                      </div>
                    </div>                    
                  </div>
              </div>
              <div class="col-md-6 mt-1">
                <!-- <a data-toggle="modal" data-target="#rlpDescription"> -->
                  <a @click="RLP()" target="_blank" data-dismiss="modal">
                  
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode != null && this.rlpCode != '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/5VS7u30m_Gc?si=2K_RXLa5Y_u_L64y`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode == '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/O2x4o_LeEqY?si=ctl_x10KvOEDzTVe`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken';
import { mapState } from 'vuex';
import services from '@/services/user';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';


export default {
  name: 'platforms',
  data() {
    return {
      rlpUse: null,
      rlpCode: null,
      teacherGrades:[],
      grade: '',
      level: '',
      roleType: '',
      querystingtext: '',
      kwquerystingtext: '',
      hexaquerystingtext: '',
      zoom: '',
      checkTime: true,
    };
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  beforeMount() {
    this.setData();
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {

    copyCode(){
      let testingCodeToCopy = document.querySelector('#rlp-code')
      testingCodeToCopy.setAttribute('type', 'text') 
      testingCodeToCopy.select()

      // try {
        document.execCommand('copy');
        // var successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
      //   alert('Testing code was copied ' + msg);
      // } catch (err) {
      //   alert('Oops, unable to copy');
      // }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    findTeacGrade(grade1, grade2){
        const grade = this.teacherGrades.filter((x) => (x >= grade1 && x <= grade2))
        if (grade.length > 0) return true; else return false;
    },

    async timeStamp() {
      const res = await services.getTimeStamp();
      if (res.data.is_weekend) return false;
      // eslint-disable-next-line
      return (res.data.data.split(' ')[1] > '08:00:00') && (res.data.data.split(' ')[1] < '17:00:00') ? true : false;
    },
    async getZoomTokenStudent() {
      // const checkTime = await this.timeStamp();
      // if (checkTime === false) return;
      const time = Date.now();
      const parsedTime = String(time).substring(0, 10);
      const fullName = `${this.userDetails.first_name} ${this.userDetails.last_name}`;
      const payload = {
        context: {
          user: {
            avatar: '',
            name: fullName,
            email: this.userDetails.email,
            affiliation: 'member',
          },
          features: {
            'screen-sharing': false,
          },
        },
        aud: 'myeducams',
        iss: 'myeducams',
        sub: 'myeducams.com',
        room: `${this.zoom[0].id}-${this.zoom[0].name}`,
        moderator: false,
        exp: Number(parsedTime) + 60,
      };
      const token = jwt.sign(payload, 'mysecret');
      window.open(`https://myeducams.com/${this.zoom[0].id}-${this.zoom[0].name}?jwt=${token}`, '_blank');
    },
    async getZoomTokenTeacher(zoomClass) {
      // const checkTime = await this.timeStamp();
      // if (checkTime === false) return;
      const time = Date.now();
      const parsedTime = String(time).substring(0, 10);
      const fullName = `${this.userDetails.first_name} ${this.userDetails.last_name}`;
      const payload = {
        context: {
          user: {
            avatar: '',
            name: fullName,
            email: this.userDetails.email,
            affiliation: 'owner',
          },
          features: {
            'screen-sharing': true,
          },
        },
        aud: 'myeducams',
        iss: 'myeducams',
        sub: 'myeducams.com',
        room: zoomClass,
        moderator: true,
        exp: Number(parsedTime) + 60,
      };
      const token = jwt.sign(payload, 'mysecret');
      window.open(`https://myeducams.com/${zoomClass}?jwt=${token}`, '_blank');
    },
    setData() {
      if (this.userDetails) {
        this.level = this.userDetails.organization.find(x => x.type === 'level').name;
        this.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        this.zoom = this.userDetails.organization.filter(x => x.type === 'grade');
        this.roleType = this.userDetails.role_type;

        this.rlpUse = this.userDetails.organization.find(x => x.type === 'campus').rlp_institute_code
        this.rlpCode = this.userDetails.rlp_code ? this.userDetails.rlp_code : null

        if ( this.roleType == 'teacher'){
          const teacherGrades = this.userDetails.organization.filter((x) => x.type === "grade")
          for (let key in teacherGrades){
            this.teacherGrades.push(teacherGrades[key].name.split("-")[0])
          }
        }


        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        if (this.userDetails.lang === 'en') {
          this.hexaquerystingtext = `?username=${btoa('steve.jobs')}&password=${btoa('090807')}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }
      }

      this.kwquerystingtext = `?username=${btoa('uesnlkw_teacher')}&password=${btoa('123456')}`;
    },
    pb() {
      if (this.roleType === 'teacher') {
        console.log(this.userDetails);
        this.personalbestUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : "";
        this.personalbestPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : "";
      } else if (this.roleType === 'student') {
        this.personalbestUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : "";
        this.personalbestPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : "";
        // this.personalbestUser = 'richmondelt18+st06b@gmail.com';
        // this.personalbestPass = 'pass';
      }
      // console.log(this.roleType);

      swal.fire({
        title: 'Richmond Learning Platform',
        html: `<b>USERNAME:</b><br> ${this.personalbestUser} <hr><b>PASSWORD:</b><br>${this.personalbestPass} `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Go',
      }).then((result) => {
        if (result.value) {
          window.open('https://richmondlp.com/login', '_blank');
        }
      });
    },

     RLP() {
      try {
        this.ready = false;
        const secretKey = "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N";
        const userData = {}
        const classData = {}
        if (this.userDetails) {
          userData.username = this.userDetails.email;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.role_type = this.userDetails.role_type;
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;

          classData.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          classData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.class = [classData];
        }


        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },


  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
